<template>
  <div v-if="commonCodeByOrder && gameCount"><!-- class="mainBg" -->
    <!--div class="main_iv">
      <div class="main_ment">
      </div>
      <div class="l_img">
         <img src="@/assets/img/right.gif" style="position:absolute;z-index:-1;">
         <img src="@/assets/img/leftt.png">
      </div>
      <div class="w_img" >
         <img src="@/assets/img/right.gif" style="position:absolute;z-index:-1;">
         <img src="@/assets/img/sloat.png">
      </div>
      <div class="bg">
         <img src="@/assets/img/main_bg.gif">
      </div>
    </div-->
    <div class="mt125">
       <transition name="fade">
         <ui-swiper />
       </transition>
    </div>
    <div class="tabWrap">
       <div class="tab_01">
         <ul>
          <li data-tab="tab-1" v-if="gameCount['casino']" @click="currentTab = 'tab-1'" :class="{'current': currentTab == 'tab-1'}">
               <!--<em><img src="@/assets/img/image_livecasino.svg"></em>-->
               <h3>{{ $t('front.gnb.livecasino') }}<span>LIVE CASINO</span></h3>
            </li>
            <li data-tab="tab-2" v-if="gameCount['hc-casino']" @click="currentTab = 'tab-2'" :class="{'current': currentTab == 'tab-2'}">
               <!--<em><img src="@/assets/img/image_hotelcasino.svg"></em>-->
               <h3>{{ $t('front.gnb.hotelcasino') }}<span>HOTEL CASINO</span></h3>
            </li>
            <li data-tab="tab-3" v-if="gameCount['slot']" @click="currentTab = 'tab-3'" :class="{'current': currentTab == 'tab-3'}">
               <!--<em><img src="@/assets/img/image_slot.svg"></em>-->
               <h3>{{ $t('front.gnb.slotgame') }}<span>SLOT GAME</span></h3>
            </li>
            <li data-tab="tab-6" v-if="gameCount['h-slot']" @click="currentTab = 'tab-6'" :class="{'current': currentTab == 'tab-6'}">
               <!--<em><img src="@/assets/img/image_hotelslot.svg"></em>-->
               <h3>{{ $t('front.gnb.hotelslot') }}<span>HOTEL SLOT</span></h3>
            </li>
            <li data-tab="tab-2" v-if="gameCount['sports']" @click="currentTab = 'tab-4'" :class="{'current': currentTab == 'tab-4'}">
               <h3>{{ $t('front.gnb.sport') }}<span>{{ $t('front.gnb.sport') }}</span></h3>
            </li>
            <li data-tab="tab-5" v-if="gameCount['mini-game']" @click="currentTab = 'tab-5'" :class="{'current': currentTab == 'tab-5'}">
               <!--<em><img src="@/assets/img/image_minigame.svg"></em>-->
               <h3>{{ $t('front.gnb.minigame') }}<span>MINI GAME</span></h3>
            </li>
         </ul>
       </div>
    </div>
    <!--section class="mt60 w1314">
      <div class="loading">
        <img src="@/assets/img/loading.gif" />
      </div>
    </section-->
    <div class="tabCon">
      <div id="tab-1" :key="'tab-1'" class="tab-content" v-if="currentTab == 'tab-1'">
        <ul class="betGames betGamesa">
          <template v-for="item in commonCodeByOrder['casino']" v-bind:key="item.vendorKey">
            <li :style="getBackgroundImage(item)"
                v-if="item.groupCodeName.indexOf('HC-') == -1"
                @click="onCasinoSelectGame(item.groupCode, item.code)"
                @mouseover="item.isHover = true"
                @mouseout="item.isHover = false"
            >
              <div class="mask">
                 <div>
                    <em class="glogo" :style="getBackgroundLogoImage(item)"></em>
                 </div>
              </div>
              <p class="name">
                {{$i18n.locale !== 'ko' ? item[`codeName${$i18n.locale.toUpperCase()}`] : item.codeName}}
                <em :class="item.groupCode">{{ $t('front.gnb.livecasino') }}</em>
              </p>
            </li>
          </template>
        </ul>
      </div><!-- tab-1 -->

      <div id="tab-2" :key="'tab-2'" class="tab-content" v-if="currentTab == 'tab-2'">
        <ul class="betGames betGamesa">
          <template v-for="item in commonCodeByOrder['casino']" v-bind:key="item.vendorKey">
            <li :style="getBackgroundImage(item)"
                v-if="item.groupCodeName.indexOf('HC-') > -1"
                @click="onCasinoSelectGame(item.groupCode, item.code)"
                @mouseover="item.isHover = true"
                @mouseout="item.isHover = false"
            >
              <div class="mask">
                 <div>
                    <em class="glogo" :style="getBackgroundLogoImage(item)"></em>
                 </div>
               </div>
              <p class="name">
                {{$i18n.locale !== 'ko' ? item[`codeName${$i18n.locale.toUpperCase()}`] : item.codeName}}
                <em :class="item.groupCode">{{ $t('front.gnb.hotelcasino') }}</em>
              </p>
            </li>
          </template>
        </ul>
      </div><!-- tab-2 -->

      <div id="tab-3" :key="'tab-3'" class="tab-content" v-if="currentTab == 'tab-3'">
        <ul class="betGames betGamesa">
          <template v-for="item in commonCodeByOrder['slot']" v-bind:key="item.vendorKey">
            <li :style="getBackgroundImage(item)"
                v-if="item.groupCodeName.indexOf('H-') === -1"
                @click="slotOpen('isSlot', 'slot', item.codeName, item.code)"
                @mouseover="item.isHover = true"
                @mouseout="item.isHover = false"
            >
            <div class="mask">
               <div>
                  <em class="glogo" :style="getBackgroundLogoImage(item)"></em>
               </div>
             </div>
              <p class="name">
                {{$i18n.locale !== 'ko' ? item[`codeName${$i18n.locale.toUpperCase()}`] : item.codeName}}
                <em :class="item.groupCode">{{ $t('front.gnb.slotgame') }}</em>
              </p>
            </li>
          </template>
        </ul>
      </div><!-- tab-3 -->

      <div id="tab-6" :key="'tab-6'" class="tab-content" v-if="currentTab == 'tab-6'">
        <ul class="betGames betGamesa">
          <template v-for="item in commonCodeByOrder['slot']" v-bind:key="item.vendorKey">
            <li :style="getBackgroundImage(item)"
                v-if="item.groupCodeName.indexOf('H-') > -1"
                @click="slotOpen('isSlot', 'slot', item.codeName, item.code)"
                @mouseover="item.isHover = true"
                @mouseout="item.isHover = false"
            >
            <div class="mask">
               <div>
                  <em class="glogo" :style="getBackgroundLogoImage(item)"></em>
               </div>
             </div>
              <p class="name">
                {{$i18n.locale !== 'ko' ? item[`codeName${$i18n.locale.toUpperCase()}`] : item.codeName}}
                <em :class="item.groupCode">{{ $t('front.gnb.hotelslot') }}</em>
              </p>
            </li>
          </template>
        </ul>
      </div><!-- tab-2 -->

      <div id="tab-4" :key="'tab-4'" class="tab-content" v-if="currentTab == 'tab-4'">
        <ul class="betGames betGamesa">
          <template v-for="item in commonCodeByOrder['sport']" v-bind:key="item.vendorKey">
            <li :style="getBackgroundImage(item)"
                @click="onCasinoSelectGame(item.groupCode, item.code)"
                @mouseover="item.isHover = true"
                @mouseout="item.isHover = false"
            >
              <p class="name">
                {{$i18n.locale !== 'ko' ? item[`codeName${$i18n.locale.toUpperCase()}`] : item.codeName}}
                <em :class="item.groupCode">{{ $t('front.gnb.sport') }}</em>
              </p>
            </li>
          </template>

        </ul>
      </div><!-- tab-4 -->

      <div id="tab-5" :key="'tab-5'" class="tab-content" v-if="currentTab == 'tab-5'">
        <ul class="betGames betGamesa">

          <template v-for="item in commonCodeByOrder['minigame']" v-bind:key="item.vendorKey">
            <li :style="getBackgroundImage(item)"
                @click="onCasinoSelectGame(item.groupCode, item.code)"
                @mouseover="item.isHover = true"
                @mouseout="item.isHover = false"
            >
              <p class="name">
                {{$i18n.locale !== 'ko' ? item[`codeName${$i18n.locale.toUpperCase()}`] : item.codeName}}
                <em :class="item.groupCode">{{ $t('front.gnb.minigame') }}</em>
              </p>
            </li>
          </template>
        </ul>
      </div><!-- tab-5 -->
    </div><!-- tab -->

    <div class="cs_Area">
      <div>
         <!-- <div class="cs_m">
           <h2 class="mainnames">{{ $t('front.main.livecharge') }}<a @click="goPageByName('')">+ MORE</a></h2>
           <ul class="mb50">
             <template v-for="item in cashInList" :key="item.memId">
               <li>
                 <span><img src="@/assets/img/icon_up.svg" />{{item.memId}}</span>
                 <span>{{thousand(Number(item.cashAmt))}} 원</span>
                 <span class="time"><img src="@/assets/img/icon_time.svg" />{{item.regDate}}</span>
               </li>
             </template>
             <template v-for="item in listMaxCount - cashInList.length" :key="item">
                <li>
                  <span>&nbsp;</span>
                  <span>&nbsp;</span>
                  <span class="time">&nbsp;</span>
               </li>
             </template>
           </ul>
           <h2 class="mainnames">{{ $t('front.main.liveExchange') }}<a @click="goPageByName('')">+ MORE</a></h2>
           <ul>
             <template v-for="item in cashOutList" :key="item.memId">
               <li>
                 <span><img src="@/assets/img/icon_down.svg" />{{item.memId}}</span>
                 <span>{{thousand(Number(item.cashAmt*-1))}} 원</span>
                 <span class="time"><img src="@/assets/img/icon_time.svg" />{{item.regDate}}</span>
               </li>
             </template>
             <template v-for="item in listMaxCount - cashOutList.length" :key="item">
               <li>
                  <span>&nbsp;</span>
                  <span>&nbsp;</span>
                  <span class="time">&nbsp;</span>
               </li>
             </template>
           </ul>
         </div> -->

         <div class="cs_n">
           <h2 class="mainnames" >{{ $t('front.common.notice') }}</h2>
           <ul>
             <template v-for="item in noticeList" :key="item">
                 <li @click="onClickNoticeRead(item)">
                 <span>
                    <em class="txt"><img src="@/assets/img/icon_noti.svg" />{{item.title}}</em>
                    <em class="time"><img src="@/assets/img/icon_time.svg" />{{item.regDate}}</em>
                 </span>
                 <span class="txt">{{item.content}}</span>
               </li>
             </template>
             <template v-for="item in listMaxCount - noticeList.length" :key="item">
               <li>
                 <span>
                    <em class="txt">&nbsp;</em>
                    <em class="time">&nbsp;</em>
                 </span>
                 <span class="txt">&nbsp;</span>
               </li>
             </template>
           </ul>
         </div>

         <div>
            <h2 class="mainnames">{{ $t('front.common.cscenter') }}</h2>
            <ul class="guidewrap">
               <li class="cursorP" @click="goPageByName('faq')"><img src="@/assets/img/ban3.png" /></li>
               <li><img src="@/assets/img/ban2.png" /></li>
               <!-- <li class="cursorP" @click="goPageByName('qnaList')"><img src="@/assets/img/ban1.png" /></li> -->
            </ul>
         </div>
      </div>
    </div>
    <!--div class="clogowrap">
       <ul class="calogo">
         <li><img src="@/assets/img/ca_logo1.png" /></li>
         <li><img src="@/assets/img/ca_logo2.png" /></li>
         <li><img src="@/assets/img/ca_logo3.png" /></li>
         <li><img src="@/assets/img/ca_logo4.png" /></li>
         <li><img src="@/assets/img/ca_logo5.png" /></li>
         <li><img src="@/assets/img/ca_logo6.png" /></li>
         <li><img src="@/assets/img/ca_logo7.png" /></li>
         <li><img src="@/assets/img/ca_logo8.png" /></li>
         <li><img src="@/assets/img/ca_logo10.png" /></li>
       </ul>
    </div-->
    <div class="mainPopWrap">
      <template v-for="(item) in popupList" v-bind:key="item.bannerIdx">
        <div class="mainPop" v-if="item.delYn == 'N' && !item.isClose" :data-idx="item.bannerIdx">
          <div class="closeWrap">
            <a class="close" @click="popupCloseByCookie(item)">{{$t('front.main.today')}}</a>
            <a class="close" @click="popupClose(item)">{{$t('front.main.close')}}</a>
          </div>
          <img :src="item.bannerImg" v-if="item.bannerImg" />
          <div class="txtwrap">
            <h3>{{item.bannerTitle}}</h3>
            <ul class="popupTxt" v-if="item.bannerContent">
              <li v-html="item.bannerContent">
              </li>
            </ul>
          </div>
        </div>
      </template>
    </div>
  </div>

   <!--div class="loginmodal">
      <div class="mloading-container">
         <div class="mloading"></div>
         <div class="mloading-text">loading</div>
      </div>
   </div-->
</template>

<script>

import '@/styles/common.css'
import { getPopupCookie, setPopupCookie, tokenCheck } from '@/libs/auth-helper'

import { getMain, getMainCashStatus, getMainNotice } from '@/api/main'
import { mapState } from 'vuex'

import store from '@/store'
import UiSwiper from '@/components/ui/UiSwiper.vue'
import { thousand } from '../libs/utils'

export default {
  name: 'Index.vue',
  components: {
    UiSwiper
  },
  created () {
    // this.emitter.emit('Loading', true)
    // this.emitter.emit('Loading', true)
    // setTimeout(() => {
    //
    // }, 5000)
    // setTimeout(() => {
    //   this.emitter.emit('Loading', false)
    // }, 8000)
    this.loadMain()
    this.popupMerge()
    this.loadNotice()
    this.loadCashStatus()
  },
  data () {
    return {
      currentTab: 'tab-1',
      popupList: {},
      cashInList: [],
      cashOutList: [],
      noticeList: [],
      listMaxCount: 4,
      noticeListMaxCount: 4
    }
  },
  watch: {
    popup () {
      this.popupMerge()
    },
    popupLogin () {
      this.popupMerge()
    },
    async userData () {
      await store.dispatch('storePopup')
      if (this.userData) {
        await store.dispatch('storePopupLogin')
      }
      await this.popupMerge()
    }
  },
  computed: {
    ...mapState([
      'userData',
      'commonCode',
      'commonCodeByOrder',
      'popup',
      'popupLogin',
      'banner',
      'gameCount'
    ])

  },
  methods: {
    onClickNoticeRead (item) {
      console.log(item)
      this.$router.push({ name: 'noticeRead', params: { boardIdx: item.boardIdx } })
    },
    thousand,
    loadCashStatus () {
      getMainCashStatus({}).then(res => {
        const result = res.data
        if (result.resultCode === '0') {
          console.log(result.data)
          this.cashInList = result.data.cashInList.slice(0, this.listMaxCount)
          this.cashOutList = result.data.cashOutList.slice(0, this.listMaxCount)
        }
      })
    },
    loadNotice () {
      getMainNotice({}).then(res => {
        const result = res.data
        if (result.resultCode === '0') {
          console.log(result)
          this.noticeList = result.data.boardList.slice(0, this.noticeListMaxCount)
        }
      })
    },
    getBackgroundImage (item) {
      try {
        const image = require('../assets/img/game_main_pc_' + item.code + '_off.png')
        const imageOff = require('../assets/img/game_main_pc_' + item.code + '_off.png')
        if (image && imageOff) {
          if (item.isHover) {
            return { backgroundImage: 'url(' + image + ')' }
          } else {
            return { backgroundImage: 'url(' + imageOff + ')' }
          }
        } else {
          return { backgroundImage: 'url()' }
        }
      } catch (e) {
        return { backgroundImage: 'url()' }
      }
    },
    getBackgroundLogoImage (item) {
      try {
        const image = require('../assets/img/glogo' + item.code + '.png')
        return { backgroundImage: 'url(' + image + ')' }
      } catch (e) {
        return { backgroundImage: 'url()' }
      }
    },
    popupMerge () {
      const popupLogin = this.userData ? this.popupLogin : []
      const pList = this.popup.concat(popupLogin)

      this.popupList = {}

      pList.forEach(item => {
        if (!item.isClose) {
          const key = item.bannerIdx
          const siteId = process.env.VUE_APP_SITE_ID || 'moka'
          const name = siteId + '_popup_' + item.bannerIdx
          const cookie = getPopupCookie(name)
          if (cookie) {
            item.isClose = true
          } else {
            item.isClose = false
          }
          if (!this.popupList[key]) {
            this.popupList[key] = item
          }
        }
      })
    },
    popupClose (item) {
      item.isClose = true
    },
    popupCloseByCookie (item) {
      item.isClose = true
      setPopupCookie(item)
    },
    slotOpen (event, groupCode, codeName, code) {
      console.log(event, { groupCode, codeName, code })
      this.emitter.emit(event, { groupCode, codeName, code })
      this.emitter.emit('Loading', false)
    },
    loadMain () {
      if (this.userData) {
        getMain({}).then(res => {
          // console.log(res)
        })
      }
    }
  },
  async beforeRouteEnter (to, from, next) {
    await tokenCheck()
    // if (result) {
    next()
    // }
  }
}
</script>
<style>
.mainSlide img{width:100%;}
.mainSlide .swiper-slide>a{width:100%;display:block;}
.mainSlide .swiper-slide>a img{display:block;width:100%;height: ;object-fit:cover;object-position:center;}
.swiper-button-next, .swiper-button-prev {bottom: 50px;top: auto;}
.swiper-button-next {
  background-image: url(../assets/img/slideright.png) !important;
  background-size: 11px 20px !important;
  background-repeat: no-repeat;
  background-position: center;
  right: 50%;
  bottom: 3px;
  margin-right: -640px;
  z-index: 11;
}
.swiper-button-prev {
  background-image: url(../assets/img/slideleft.png) !important;
  background-size: 11px 20px !important;
  background-repeat: no-repeat;
  background-position: center;
  left: 50%;
  bottom: 3px;
  margin-left: -640px;
  z-index: 11;
}
.swiper-button-next:after, .swiper-button-prev:after {content: '';}
.swiper-button-next:hover, .swiper-button-prev:hover {opacity: 1;}
.swiper-pagination {bottom: 0 !important;gap: 100px;background-color: ;height: 50px;display: flex;justify-content: center;align-items: center;}
.swiper-pagination-bullet {width: 105px; min-height: 19px; background: transparent; opacity: 0.3; color: #fff;}
.swiper-pagination-bullet-active {border-radius: 0;opacity: 1;}
</style>
<style scoped src="@/styles/header.css"></style>
<style scoped src="@/styles/footer.css"></style>
<style scoped src="@/styles/main.css"></style>
